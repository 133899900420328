<template>
  <v-container :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row>
      <v-col>
        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! all flow fields                                                                                              ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <!-- -------------------------------------------------------------------------------------------------------- ->
          <! flow identification                                                                                        ->
          <!- ----------------------------------------------------------------------------------------------------------->
          <v-col>
            <v-card outlined>
              <v-card-title>flow ID and status</v-card-title>
              <v-col>
                <v-row>
                  <v-col cols="3" class="py-0 my-0">
                    <w-text-field
                      :text="flow.reference"
                      label="reference"
                      :go-to="readFlowConfigPath"
                      :go-to-ref="flow.flowConfig.reference"
                    />
                    <w-text-field :text="flow.name" label="name" />
                  </v-col>

                  <v-col cols="2" class="py-0 my-0">
                    <w-text-field :text="flow.createdAt" label="created" />
                    <w-text-field
                      :text.sync="flow.customReference"
                      label="custom ref."
                      editable
                    />
                  </v-col>

                  <v-col cols="2" class="py-0 my-0">
                    <w-text-field :text="flow.startedAt" label="started" />
                    <w-text-field :text="flow.finishedAt" label="finished" />
                  </v-col>

                  <v-col cols="1" class="py-0 my-0">
                    <w-text-field :text="flow.statusString" label="status" />
                    <w-text-field :text="flow.totalElapsed" label="duration" />
                  </v-col>

                  <v-col cols="1">
                    <v-spacer />
                  </v-col>

                  <!-- buttons start -->
                  <v-col cols="3" align-self="center">
                    <v-row>
                      <v-col align-self="end">
                        <!-- run flow -->
                        <v-tooltip top v-if="!pollingInterval">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="green"
                              v-bind="attrs"
                              v-on="on"
                              @click="runFlow"
                            >
                              <v-icon dark>mdi-play</v-icon>
                            </v-btn>
                          </template>
                          <span>start or resume flow</span>
                        </v-tooltip>
                        <!-- stop polling (refresh) -->
                        <v-tooltip top v-if="pollingInterval">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="grey"
                              v-bind="attrs"
                              v-on="on"
                              @click="stopPolling"
                            >
                              <v-icon dark>mdi-stop</v-icon>
                            </v-btn>
                          </template>
                          <span>stop refreshing the view</span>
                        </v-tooltip>
                        <!-- pause flow -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="orange"
                              v-bind="attrs"
                              v-on="on"
                              @click="pauseFlow"
                            >
                              <v-icon dark>mdi-pause</v-icon>
                            </v-btn>
                          </template>
                          <span>pause flow - not implemented yet</span>
                        </v-tooltip>
                        <w-confirm-dialog ref="pauseDialog" />
                        <!-- cancel flow -->
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="red"
                              v-bind="attrs"
                              v-on="on"
                              @click="cancelFlow"
                            >
                              <v-icon dark>mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>cancel flow - not implemented yet</span>
                        </v-tooltip>
                        <w-confirm-dialog ref="cancelDialog" />
                        <!-- run flow -->
                        <v-tooltip top v-if="!showAllTasks">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="violet"
                              v-bind="attrs"
                              v-on="on"
                              @click="showAllTasks = true"
                            >
                              <v-icon dark>mdi-eye-off</v-icon>
                            </v-btn>
                          </template>
                          <span>show all tasks</span>
                        </v-tooltip>
                        <!-- stop polling (refresh) -->
                        <v-tooltip top v-if="showAllTasks">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              class="ml-4 mb-5"
                              fab
                              dark
                              x-large
                              color="blue"
                              v-bind="attrs"
                              v-on="on"
                              @click="showAllTasks = false"
                            >
                              <v-icon dark>mdi-eye</v-icon>
                            </v-btn>
                          </template>
                          <span>active compact view</span>
                        </v-tooltip>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!-- buttons end -->
                </v-row>
              </v-col>
            </v-card>
          </v-col>
        </v-row>

        <!-- ---------------------------------------------------------------------------------------------------------- ->
        <! Items                                                                                                        ->
        <!- ------------------------------------------------------------------------------------------------------------->
        <v-row>
          <v-col>
            <v-card class="elevation-6">
              <w-tabs>
                <v-tab>tasks</v-tab>
                <v-tab-item>
                  <w-entity-list
                    :columns="taskColumns"
                    :items="flow.tasks"
                    :read-path="readTaskPath"
                    read-ref="id"
                    title="running tasks"
                    group-by="group name"
                    progress-col="donePct"
                  />
                </v-tab-item>

                <v-tab @click="forceShowFlow">display</v-tab>
                <v-tab-item>
                  <vue-mermaid-string
                    v-if="showDiagram"
                    :value="diagram"
                    @node-click="goToTask"
                  />
                </v-tab-item>

                <v-tab>logs</v-tab>
                <v-tab-item>
                  <w-entity-list
                    :columns="logColumns"
                    :items="flow.logs"
                    title="logs"
                    sort-by="time,desc"
                  />
                </v-tab-item>
              </w-tabs>
            </v-card>
          </v-col>
        </v-row>
        <!-- --------------------------------------------------------------------------------------------------------- -->
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {
  VBtn,
  VCard,
  VCardTitle,
  VCol,
  VContainer,
  VIcon,
  VRow,
  VSpacer,
  VTab,
  VTabItem,
  VTooltip,
} from "vuetify/lib";
import VueMermaidString from "vue-mermaid-string";
import endent from "endent";

import api from "../../components/external/modules/shared/utils/api";
import WConfirmDialog from "../../components/external/modules/shared/views/WConfirmDialog.vue";
import WEntityList from "../../components/external/modules/shared/views/WEntityList.vue";
import WTabs from "../../components/external/modules/shared/views/WTabs.vue";
import WTextField from "../../components/external/modules/shared/views/WTextField.vue";
import path from "../../shared/paths";
import datehourformat from "../../components/external/modules/shared/utils/datehourformat";
import lsync from "../../components/external/modules/shared/utils/lsync";

export default {
  components: {
    VBtn,
    VCard,
    VCardTitle,
    VCol,
    VContainer,
    VIcon,
    VRow,
    VSpacer,
    VTab,
    VTabItem,
    VTooltip,
    VueMermaidString,
    WConfirmDialog,
    WEntityList,
    WTabs,
    WTextField,
  },

  computed: {
    diagram: {
      get: function () {
        return this.mermaidContent;
      },
      set: function (val) {
        this.mermaidContent = endent`${val}`;
      },
    },
  },

  data: () => ({
    flow: {
      flowConfig: {},
      tasks: [],
      logs: [],
    },

    showDiagram: false,
    mermaidContent: "",
    showAllTasks: false,

    logColumns: [
      { value: "time", text: "time" },
      { value: "taskRef", text: "task ref." },
      { value: "taskName", text: "task name" },
      { value: "progress", text: "progress %" },
      { value: "elapsedString", text: "elapsed" },
      { value: "comment", text: "comment" },
    ],

    taskColumns: [
      { value: "completeName", text: "name", groupable: false },
      { value: "manual", text: "manual", groupable: false },
      { value: "start", text: "started", groupable: false },
      { value: "statusString", text: "status", groupable: false },
      { value: "donePct", text: "done %", groupable: false },
      { value: "finish", text: "finished", groupable: false },
      { value: "lastComment", text: "last comment", groupable: false },
      { value: "group name", text: "group name", groupable: true },
    ],

    readTaskPath: path.TASK,
    readFlowConfigPath: path.TASKFLOWCONFIG,

    pollingInterval: "",

    debounceTimeout: null,
  }),

  created() {
    // building unique IDs (= keys for the local storage)
    this.flowUid = this.$route.path;

    this.showAllTasksUid = this.flowUid + "-showAllTasks";
    this.showAllTasksString = lsync(this.showAllTasksUid) || "false";
    this.showAllTasks = this.showAllTasksString === "true";

    // fetching the data
    this.loadFlow();
  },

  methods: {
    goToTask(taskGlobalRef) {
      const task = this.flow.tasks.find(
        (task) => task.globalReference === taskGlobalRef
      );
      this.$router.push({
        name: this.readTaskPath,
        params: { which: task.id },
      });
    },

    loadFlow() {
      api
        .get("taskflow/display/" + this.$route.params.which, {
          params: { forceShowAll: this.showAllTasks },
        })
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            // setting the main object
            this.flow = res.data.entity;
            this.flow.createdAt = datehourformat(this.flow._creation);
            if (this.flow.started) {
              this.flow.startedAt = datehourformat(this.flow.started);
            }
            if (this.flow.finished) {
              this.flow.finishedAt = datehourformat(this.flow.finished);
            }
            this.flow.tasks = [];

            // setting the diagram
            this.diagram = res.data.entity.mermaidString;
            this.showDiagram = true;

            // tweaking each task
            if (this.flow.groups) {
              this.flow.groups.forEach((group) => {
                group.tasks.forEach((task) => {
                  // setting the group as the name of the group instead of the object
                  task["group name"] = group.name;

                  // some more tweaks
                  task.completeName = "[" + task.num + "] " + task.name;
                  if (task.started) {
                    task.start = datehourformat(task.started, true);
                  }
                  task.donePct = task.currentProgress;
                  if (task.finished) {
                    task.finish =
                      datehourformat(task.finished, true) +
                      " in " +
                      task.totalElapsed;
                  } else {
                    if (task.etaString) {
                      task.finish = "in " + task.etaString + " (eta)";
                    }
                  }

                  // adding the task to the flow
                  if (this.showAllTasks) {
                    this.flow.tasks.push(task);
                  } else {
                    // in compact view, we only show the head, tail, and the ongoing tasks
                    if (
                      !task.compactView ||
                      task.intervalMin === task.intervalMax ||
                      task.status === 5
                    ) {
                      this.flow.tasks.push(task);
                    }
                  }
                });
              });
            } else {
              this.flow.groups = [];
            }

            // tweaking each log
            if (this.flow.logs) {
              this.flow.logs.forEach((log) => {
                log.time = datehourformat(log._creation, true, true);
              });
            } else {
              this.flow.logs = [];
            }

            // making sure we stop polling for naught
            if (this.flow.status !== 5) {
              this.stopPolling();
            } else {
              this.startPolling();
            }
          } else {
            console.log(new Error("Failed searching the task flow config"));
            console.log(res);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    runFlow() {
      api
        .get("taskflow/run/" + this.$route.params.which)
        .then((res) => {
          if (res.data.status === 200 && res.data.entity !== null) {
            this.startPolling();
          }
          this.notify(res.data.messages);
        })
        .catch((err) => {
          this.notify(err.response.data.messages);
        });
    },

    async pauseFlow() {
      if (
        await this.$refs.pauseDialog.open(
          "Please confirm",
          "Are we pausing the flow?"
        )
      ) {
        alert("will pause... but doing nothing for now");
      }
    },

    async cancelFlow() {
      if (
        await this.$refs.cancelDialog.open(
          "Please confirm",
          "Are you sure you want to cancel the flow?"
        )
      ) {
        alert("will cancel... but doing nothing for now");
      }
    },

    forceShowFlow() {
      // we use this to make sure that, after toggling the compact view button, the diagram is forced to appear
      this.showDiagram = false;
      this.$nextTick(() => {
        this.showDiagram = true;
      });
    },

    startPolling() {
      // not doing this several times
      if (!this.pollingInterval) {
        this.pollingInterval = setInterval(() => {
          // if we're not viewing the flow anymore, which happens when we open a task for instance, then we make sure we stop the polling
          if (this.$route.name !== path.TASKFLOW) {
            this.stopPolling();
            return;
          }

          // this is the thing we're doing repeatedly :
          this.loadFlow();
        }, this.flow.refreshViewEveryNum);
      }
    },

    stopPolling() {
      clearInterval(this.pollingInterval);
      this.pollingInterval = "";
    },
  },

  watch: {
    showAllTasks() {
      this.loadFlow();

      this.showAllTasksString = lsync(
        this.showAllTasksUid,
        this.showAllTasks === true ? "true" : "false"
      );
    },

    "flow.customReference"(newValue, oldValue) {
      if (!oldValue) {
        oldValue = ""; // to make sure we're comparing the same stuff just below
      }
      if (!newValue) {
        newValue = ""; // to make sure we're comparing the same stuff just below
      }
      if (oldValue != newValue) {
        if (this.debounceTimeout) clearTimeout(this.debounceTimeout);
        this.debounceTimeout = setTimeout(() => {
          // debouncing this
          api
            .post("taskflow/update/", this.flow)
            .then((res) => {
              if (res.data.status === 200 && res.data.entity !== null) {
                this.$snotify.success("this flow has been updated");
                this.loadFlow();
              } else {
                console.log("issue while updating the flow:", res);
              }
            })
            .catch((err) => {
              if (err.response) {
                this.notify(err.response.data.messages);
              } else {
                console.log(err);
              }
            });
          // debouncing end
        }, 1000);
      }
    },
  },
};
</script>

<style scoped>
.active-tab {
  background-color: #eef;
}
</style>
