var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":_vm.$vuetify.breakpoint.lgAndDown}},[_c('v-row',[_c('v-col',[_c('v-row',[_c('v-col',[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_vm._v("flow ID and status")]),_c('v-col',[_c('v-row',[_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"3"}},[_c('w-text-field',{attrs:{"text":_vm.flow.reference,"label":"reference","go-to":_vm.readFlowConfigPath,"go-to-ref":_vm.flow.flowConfig.reference}}),_c('w-text-field',{attrs:{"text":_vm.flow.name,"label":"name"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"2"}},[_c('w-text-field',{attrs:{"text":_vm.flow.createdAt,"label":"created"}}),_c('w-text-field',{attrs:{"text":_vm.flow.customReference,"label":"custom ref.","editable":""},on:{"update:text":function($event){return _vm.$set(_vm.flow, "customReference", $event)}}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"2"}},[_c('w-text-field',{attrs:{"text":_vm.flow.startedAt,"label":"started"}}),_c('w-text-field',{attrs:{"text":_vm.flow.finishedAt,"label":"finished"}})],1),_c('v-col',{staticClass:"py-0 my-0",attrs:{"cols":"1"}},[_c('w-text-field',{attrs:{"text":_vm.flow.statusString,"label":"status"}}),_c('w-text-field',{attrs:{"text":_vm.flow.totalElapsed,"label":"duration"}})],1),_c('v-col',{attrs:{"cols":"1"}},[_c('v-spacer')],1),_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('v-row',[_c('v-col',{attrs:{"align-self":"end"}},[(!_vm.pollingInterval)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"green"},on:{"click":_vm.runFlow}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-play")])],1)]}}],null,false,3911496042)},[_c('span',[_vm._v("start or resume flow")])]):_vm._e(),(_vm.pollingInterval)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"grey"},on:{"click":_vm.stopPolling}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-stop")])],1)]}}],null,false,1712875896)},[_c('span',[_vm._v("stop refreshing the view")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"orange"},on:{"click":_vm.pauseFlow}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-pause")])],1)]}}])},[_c('span',[_vm._v("pause flow - not implemented yet")])]),_c('w-confirm-dialog',{ref:"pauseDialog"}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"red"},on:{"click":_vm.cancelFlow}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-close")])],1)]}}])},[_c('span',[_vm._v("cancel flow - not implemented yet")])]),_c('w-confirm-dialog',{ref:"cancelDialog"}),(!_vm.showAllTasks)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"violet"},on:{"click":function($event){_vm.showAllTasks = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye-off")])],1)]}}],null,false,1295561058)},[_c('span',[_vm._v("show all tasks")])]):_vm._e(),(_vm.showAllTasks)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-4 mb-5",attrs:{"fab":"","dark":"","x-large":"","color":"blue"},on:{"click":function($event){_vm.showAllTasks = false}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-eye")])],1)]}}],null,false,2579055512)},[_c('span',[_vm._v("active compact view")])]):_vm._e()],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"elevation-6"},[_c('w-tabs',[_c('v-tab',[_vm._v("tasks")]),_c('v-tab-item',[_c('w-entity-list',{attrs:{"columns":_vm.taskColumns,"items":_vm.flow.tasks,"read-path":_vm.readTaskPath,"read-ref":"id","title":"running tasks","group-by":"group name","progress-col":"donePct"}})],1),_c('v-tab',{on:{"click":_vm.forceShowFlow}},[_vm._v("display")]),_c('v-tab-item',[(_vm.showDiagram)?_c('vue-mermaid-string',{attrs:{"value":_vm.diagram},on:{"node-click":_vm.goToTask}}):_vm._e()],1),_c('v-tab',[_vm._v("logs")]),_c('v-tab-item',[_c('w-entity-list',{attrs:{"columns":_vm.logColumns,"items":_vm.flow.logs,"title":"logs","sort-by":"time,desc"}})],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }